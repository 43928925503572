
import React from 'react'
import { List, Footer, Ellipsis, NavBar, Swiper, Image, Tabs, Result } from 'antd-mobile'
import { createFromIconfontCN } from '@ant-design/icons';
import './index.scss';

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4335219_nhipwl2o8u8.js',
});



const G1menu = () => {
  const token = JSON.parse(localStorage.getItem('cx315_token'))
console.log("数量为:"+token.qjson[0].glmsg[0].sum)
  return <>
    <NavBar back={null} style={{
      '--height': '40px',
      background: '#02af6f',
      color: '#FFF',
      // '--border-bottom': '1px #eee solid',
    }}>
      {token.ttm}
    </NavBar>
    <Swiper autoplay>
      {token.ys_bn[0].bsh.map((k) => <Swiper.Item key={`topimg${k.xh}`}><Image src={k.baimg} /></Swiper.Item>)}


    </Swiper>


    <Tabs style={{ '--active-title-color': '#02af6f', '--active-line-color': '#02af6f', '--content-padding': '0px' }} >
      <Tabs.Tab title='追溯信息'
        // style={{'--active-title-color':'#02af6f','--active-line-color':'#02af6f'}}
        key='m1'>

        <List style={{ '--font-size': '1em' }}>
          <List.Item extra={token.resq.dbcode} > 单元识别码</List.Item>


          <List.Item description={<Ellipsis
            direction='end'
            content={`http://ddspp.cn/G/${token.resq.dbcode}`}
            expandText='展开'
            collapseText='收起'
          />} >
            追溯网址
          </List.Item>
          <List.Item extra={token.dbno} > 数据批次</List.Item>
          <List.Item extra={token.dblx} > 数据类型</List.Item>
          { token.glsum &&  <List.Item extra={ token.glsum} > 关联数量</List.Item> } 

        </List>

        <div style={{
          width: '400px',
          height: '39px',
          background: '#02af6f',
          'background-image': 'linear-gradient(to right, #02af6f, #fff)',
          color: '#FFF',
          lineHeight: '39px', paddingLeft: '7px'
          // '--border-bottom': '1px #eee solid',
        }}>

          企业信息
        </div>

        <List style={{ '--font-size': '1em' }}>
          {token.qmsgjson.map((e) => <List.Item extra={e.mmsg} > {e.title}</List.Item>)}




        </List>

      </Tabs.Tab>

      <Tabs.Tab title='认证信息' key='m3' >

        {token.rzzt === 'Y' && <Result
          icon={<IconFont type="icon-yiyanzheng" style={{
            fontSize: '5em',
          }} />}
          status='success'
          title='已认证主体'
          description='企业认证为核准工商登记信息及对公账户均正确，且对公收款金额验证通过的结果。'
        />}

        {token.rzzt === 'N' && <Result
          icon={<IconFont type="icon-weiyanzheng" style={{
            fontSize: '5em',
          }} />}
          status='warning'
          title='未认证主体'
          description='企业认证到期、过期或者从未进行过企业认证的主体。'
        />}




      </Tabs.Tab>

    </Tabs>



    <Footer
      label='以下无内容'
      content='@ 技术支持 胜聪网 www.ddspp.com'
      className='footer'

    ></Footer>




  </>
}

export default G1menu;